<template>
  <div class="setup-finanziamenti">

    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col">
        <h4>Aggiungi i tuoi finanziamenti</h4>
      </div>
    </div>
    <div class="table-wrapper my-4">
      <b-table
        class="w-100"
        id="table"
        ref="table"
        :items="loans"
        :fields="fields"
        :busy.sync="isBusy"
        hover
        show-empty
        :key="`${lengthOfLoans}_${loansTableKey}`"
      >
        <template #cell(paidDate)="row">
          {{ row.item.paidDate ? toFormattedDate(row.item.paidDate) : '' }}
        </template>
        <template #cell(bankId)="row">
          <img :src="getBankById(row.item.bankId)?.logo" class="bank-logo sm">
          {{ getBankById(row.item.bankId)?.shortName }}
        </template>
        <template #cell(extinctionDate)="row">
          {{ row.item.extinctionDate ? toFormattedDate(row.item.extinctionDate) : '' }}
        </template>
        <template #cell(type)="row">
          {{ row.item.type | movement | capitalizeFirstLetter }}
        </template>
        <template #cell(importo)="row">
          <CellContent
            :value="row.item.importo ? parseFloat(row.item.importo) : null"
            class="d-flex justify-content-end m-0 p-0 border-0"
          />
        </template>
        <template #cell(residuo)="row">
          <CellContent
            :value="row.item.residuo ? parseFloat(row.item.residuo) : null"
            class="d-flex justify-content-end m-0 p-0 border-0"
          />
        </template>
        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            id="dropdown-1"
            right
            variant="link"
            toggle-class="text-decoration-none"
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item
              @click="
                selectedRow = row.item;
                editLoan(row.item);
              "
              >Modifica</b-dropdown-item
            >
            <b-dropdown-item
              @click="
                togglePopup('deleteLoan');
                selectedRow = row.item;
                selectedRowIndex = row.index;
              "
              class="red"
              >Elimina</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template #empty>
          <div class="d-flex justify-content-center align-items-center py-2">
            Nessun risultato trovato
          </div>
        </template>
      </b-table>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-end">
          <button class="btn btn-fin" @click="loanToEdit = null; togglePopup('upsertLoan')">
              Aggiungi finanziamento
          </button>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-6">
        <custom-button
          :isLoading="loading"
          label="Indietro"
          class="w-100 opaque"
          @click.prevent.native="$emit('changePage', -1)"
        />
      </div>
      <div class="col-6">
        <custom-button
          :isLoading="loading"
          label="Avanti"
          class="w-100"
          @click.prevent.native="onSubmit"
        />
      </div>
    </div>

    <Popup ref="upsertLoan" class="xl">
      <template #fullContent>
        <UpsertLoan :banks="banks" :setup="true" :companyId="null" @save="upsertLoan" :loan="loanToEdit" :instalmentToEdit="instalmentToEdit" :instalmentToDelete="instalmentToDelete" @openEditInstalmentPopup="openEditInstalmentPopup" @openDeleteInstalmentPopup="openDeleteInstalmentPopup"/>
      </template>
    </Popup>

    <Popup ref="deleteLoan" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Il finanziamento <b>{{ selectedRow.label }}</b> di {{ getBankById(selectedRow.bankId).shortName }} e tutti i movimenti ad esso associati verranno eliminati definitivamente.
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <!-- Special Popups -->
    <Popup ref="deleteInstalment" class="md" :errorMessage="errorMessage">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        La rata n. {{instalment.nRata}} con scadenza {{toFormattedDate(instalment.date)}}
        {{instalment.amount ? ('di ' + parseFloat(instalment.amount).toFixed(2) + ' €') : ''}}
        verrà rimossa
      </template>
      <template #button>
          <custom-button
          label="Rimuovi"
          class="w-100 mt-4"
          @click.prevent.native="deleteInstalment(instalment)"
          />
      </template>
    </Popup>

    <Popup ref="editInstalment" class="md" :errorMessage="errorMessage">
      <template #fullContent>
        <edit-instalment :instalment="instalment" @save="saveInstalment" key="editInstalmentInLoans" ref="editInstalmentInLoans"/>
      </template>
    </Popup>

  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import { toFormattedDate } from '@/helpers/dates.js';
import { BDropdown, BDropdownItem, BTable } from 'bootstrap-vue';

import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import EditInstalment from '@/views/components/Forms/Loans/EditInstalment.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertLoan from '@/views/components/Forms/Loans/UpsertLoan.vue';

export default {
  props: {
    propForm: {
      type: Object,
      required: true,
    },
    setupBanks: Array,
  },
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    'custom-button': Button,
    CellContent,
    EditInstalment,
    Popup,
    UpsertLoan,
  },
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      // banks: [],
      fields: [
        { key: 'label', label: 'Finanziamento', sortable: false },
        { key: 'bankId', label: 'Banca', sortable: false },
        { key: 'importo', label: 'Importo orignario', sortable: false, class: 'text-right' },
        { key: 'paidDate', label: 'Data erogazione', sortable: true },
        { key: 'actions', label: '', sortable: false },
      ],

      errorMessage: null,
      showPopup: true,
      snackbarText: '',
      loading: false,
      isLoadingPage: true,
      selectedRow: null,
      selectedRowIndex: null,
      loansTableKey: false,

      // Popup
      instalment: null,
      instalmentToEdit: null,
      instalmentToDelete: null,
      loanToEdit: null,

      // Table
      isBusy: false,

      form: JSON.parse(JSON.stringify(this.propForm)),
      loans: this.propForm.loans,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
    loadedBanks () {
      return this.setupBanks ? this.setupBanks.length : 0;
    },
    banks: {
      get () {
        if (this.setupBanks.length > 0) return this.setupBanks;
        return [];
      },
      set (newValue) {
        return newValue;
      },
    },
    lengthOfLoans () {
      return this.loans?.length;
    },
  },
  watch: {
    lengthOfLoans () {
      this.form = Object.assign(this.form, { loans: this.loans });
      this.$emit('updateLoans', this.form);
    },
    loans: {
      handler () {
        this.loansTableKey = !this.loansTableKey;
      },
      deep: true,
    },
  },
  methods: {
    editLoan (loan) {
      this.loanToEdit = { ...loan };
      this.togglePopup('upsertLoan');
    },
    getBankById (bankId) {
      return this.banks?.find((bank) => bank.id === bankId);
    },
    onDelete () {
      this.$log.debug(this.selectedRow);
      this.$log.debug(this.selectedRowIndex);
      this.loans.splice(this.selectedRowIndex, 1);
      this.togglePopup('deleteLoan');
      this.selectedRow = null;
      this.selectedRowIndex = null;
    },
    onEdit () {
      this.togglePopup('upsertLoan');
      this.snackbarText = 'Modifiche salvate';
      this.$emit('openSnackbar', this.snackbarText);
    },
    onSubmit () {
      this.form = Object.assign(this.form, { loans: this.loans });
      this.$emit('saveData', this.form, true);
    },
    upsertLoan (newLoan) {
      this.$log.debug(JSON.stringify(newLoan));
      if (this.loanToEdit) {
        this.loans.map((loan, i) => {
          const a = Object.entries(loan).sort().toString();
          const b = Object.entries(this.loanToEdit).sort().toString();
          if (a === b) {
            // this.loans.splice(i, 1, { ...newLoan });
            // this.loans[i] = JSON.parse(JSON.stringify(newLoan));
            this.loans.splice(i, 1, JSON.parse(JSON.stringify(newLoan)));

            this.$refs.upsertLoan.close();
            this.snackbarText = 'Finanziamento modificato';
            this.$emit('openSnackbar', this.snackbarText);
          }
        });
        this.loanToEdit = null;
        this.selectedRow = null;
        this.selectedRowIndex = null;
      } else {
        this.$log.debug('insert');
        this.loans.unshift(newLoan);
        this.togglePopup('upsertLoan');
        this.snackbarText = 'Finanziamento aggiunto';
        this.$emit('openSnackbar', this.snackbarText);
      }
    },
    searchInBanks () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toFormattedDate (s) {
      return toFormattedDate(s);
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    openEditInstalmentPopup (item) {
      this.instalment = item;
      this.togglePopup('editInstalment');
    },
    openDeleteInstalmentPopup (item) {
      this.instalment = item;
      this.togglePopup('deleteInstalment');
    },
    saveInstalment (item) {
      this.snackbarText = 'Rata aggiornata';
      this.instalmentToEdit = JSON.parse(JSON.stringify(item));
      this.$refs.snackbarLoans.openSnackbar();
      this.togglePopup('editInstalment');
    },
    deleteInstalment (item) {
      this.snackbarText = 'Rata eliminata';
      this.instalmentToDelete = JSON.parse(JSON.stringify(item));
      this.$refs.snackbarLoans.openSnackbar();
      this.togglePopup('deleteInstalment');
    },
  },
};
</script>

<style lang="scss">
.setup-finanziamenti {
  // row settings
  .dropdown {
    .dropdown-menu {
        border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
          outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
          color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
            outline: none;
        }
        &:active {
            background: $primary-light;
        }
      }
    }
  }

  .dropdown-toggle {
    padding: 0;
    margin: 0;
    &::after {
        display: none;
    }
  }

  // selected bank box
  .box {
      border: none;
    // background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }
  .table-wrapper {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      /*
      th {
        white-space: nowrap;
        padding: 23px 15px;
      }
      */
    }
  }
}
</style>
