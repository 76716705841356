<template>
  <div class="setup">
    <div v-if="isLoadingPage">
      <Loader />
    </div>
    <div class="container-fluid">
      <div class="row mt-5">
        <div class="col-12 d-flex justify-content-center">
          <div class="logo">
            <img :src="logo" alt="NinaFin logo">
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-3">
          <div class="pretty-card">
            <div class="stepper">
              <div class="step" @click="goToPage(0)" :class="{disabled: page+1 < 1, completed: steps[0]?.completed && page !== 0, active: page === 0}">
                <div class="dot"></div>
                <div class="name">Dati Azienda</div>
              </div>
              <div class="step" @click="goToPage(1)" :class="{disabled: page+1 < 2, completed: steps[1]?.completed && page !== 1, active: page === 1}">
                <div class="dot"></div>
                <div class="name">Conti Correnti</div>
              </div>
              <div class="step" @click="goToPage(2)" :class="{disabled: page+1 < 3, completed: steps[2]?.completed && page !== 2, active: page === 2}">
                <div class="dot"></div>
                <div class="name">Dipendenti</div>
              </div>
              <div class="step" @click="goToPage(3)" :class="{disabled: page+1 < 4, completed: steps[3]?.completed && page !== 3, active: page === 3}">
                <div class="dot"></div>
                <div class="name">Finanziamento</div>
              </div>
              <div v-if="false" class="step" @click="goToPage(4)" :class="{disabled: page+1 < 5, completed: steps[4]?.completed && page !== 4, active: page === 4}">
                <div class="dot"></div>
                <div class="name">Setup completato</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="pretty-card">
            <div v-show="!formHasLoaded" class="custom-loader-container">
              <Loader2 class="custom-loader" />
            </div>
            <div v-show="page === 0 && formHasLoaded">
              <SetupAzienda v-if="formHasLoaded" :propForm="form" @changePage="changePage" @saveData="saveData" />
            </div>
            <div v-show="page === 1 && formHasLoaded">
              <SetupContiCorrenti v-if="formHasLoaded" :propForm="form" :banks="banks"  @changePage="changePage" @saveData="saveData" />
            </div>
            <div v-show="page === 2 && formHasLoaded">
              <SetupDipendenti v-if="formHasLoaded" :propForm="form" :banks="userBanks" @changePage="changePage" @saveData="saveData" />
            </div>
            <div v-show="page === 3 && formHasLoaded">
              <SetupFinanziamenti v-if="formHasLoaded" :propForm="form" :setupBanks="userBanks" @changePage="changePage" @saveData="saveData" @updateLoans="updateLoans" @openSnackbar="openSnackbar" />
            </div>
            <div v-show="page === 4 && formHasLoaded" class="text-center">
              <img :src="img" alt="" class="mb-4">
              <h4 class="text-center mb-4">Complimenti!</h4>
              <p class="text-center mb-4">
                Hai completato il setup. Ora puoi utilizzare NinaFin.
              </p>
              <router-link :to="{ name: 'dashboard', params: { firstAccess: true } }" tag="button" class="btn btn-fin">
                Vai alla dashboard
              </router-link>
            </div>

            <ErrorCard v-if="errorMessage">
                <template #message >
                {{ errorMessage }}
                </template>
            </ErrorCard>
          </div>

        </div>
      </div>
    </div>

    <Snackbar :text="snackbarText" ref="snackbarLoans" class="up"/>
  </div>
</template>

<script>
import SetupAzienda from '@/views/components/Setup/SetupAzienda.vue';
import SetupContiCorrenti from '@/views/components/Setup/SetupContiCorrenti.vue';
import SetupDipendenti from '@/views/components/Setup/SetupDipendenti.vue';
import SetupFinanziamenti from '@/views/components/Setup/SetupFinanziamenti.vue';

import ErrorCard from '@/views/components/ErrorCard.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Loader2 from '@/views/components/Layout/Spinner.vue';
import logo from '@/assets/images/fin-big-logo.svg';
import img from '@/assets/images/illustration.svg';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    SetupAzienda,
    SetupContiCorrenti,
    SetupDipendenti,
    SetupFinanziamenti,
    ErrorCard,
    Loader,
    Loader2,
    Snackbar,
  },
  data () {
    return {
      isLoadingPage: true,
      formHasLoaded: false,
      loading: false,
      snackbarText: '',
      errorMessage: '',

      page: 0,
      logo: logo,
      img: img,
      banks: [],
      usersBanks: [],

      steps: [
        { step: 0, completed: false },
        { step: 1, completed: false },
        { step: 2, completed: false },
        { step: 3, completed: false },
      ],

      form: {
        // Step 1
        businessName: '',
        iva: '',
        fiscalCode: '',
        ateco: '',
        address: '',
        province: '',
        municipality: '',
        cap: null,
        address2: '',
        province2: '',
        municipality2: '',
        cap2: null,
        // Step 2
        bankAccounts: [],
        cash: null,
        // Step 3
        f24: null,
        f24BankId: null,
        payday: null,
        salary: null, // defaultView
        numberOfMonths: null, // defaultView
        employees: [],
        salariesBankId: null,
        // Step 4
        loans: [],
      },
    };
  },
  computed: {
    userBanks () {
      if (this.banks && this.form.bankAccounts) {
        return this.banks.filter(bank => {
          return this.form.bankAccounts.find(account => account.bankId === bank.id);
        });
      } else return null;
    },
    formIsEmpty: {
      get () {
        return this.form ? Object.keys(this.form).length === 0 : null;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    changePage (back = false) {
      this.errorMessage = '';
      localStorage.setItem('step', this.page + 1);

      return back ? this.page-- : this.page++;
    },
    getBanks () {
      this.$api.fetchBanksList()
        .then((res) => {
          this.banks = res.data.bankDetails;
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getSetup () {
      this.$api.getSetup()
        .then((res) => {
          const newForm = res.data.form;
          if (newForm && Object.keys(res.data.form).length > 0) {
            this.form = newForm;
          }
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.formHasLoaded = true;
        });
    },
    goToPage (i) {
      if (this.steps[i]?.completed) {
        this.page = i;
        this.errorMessage = '';
      }
    },
    openSnackbar (text) {
      this.snackbarText = text;
      this.$refs.snackbarLoans.openSnackbar();
    },
    saveData (form, isCompleted = false) {
      this.form = Object.assign(this.form, form);
      this.formHasLoaded = false;
      if (isCompleted === true) {
        this.$log.debug('setup completed');
      }

      this.$api.updateSetup(this.form, isCompleted)
        .then((res) => {
          // this.$log.debug(res.data);
          if (isCompleted === true) {
            localStorage.removeItem('step');
          }
          this.changePage();
        })
        .catch((e) => {
          this.$log.error(e);
          this.errorMessage = "Qualcosa è andato storto, riprova tra poco o contatta l'assistenza se il problema persiste";
        })
        .finally(() => {
          this.getSetup(); // updates formHasLoaded
        });
    },
    updateLoans (form) {
      this.form = Object.assign(this.form, form);
      // this.formHasLoaded = false;
      this.$api.updateSetup(this.form)
        .then((res) => {
          this.$log.debug(res.data);
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          // this.getSetup(); // updates formHasLoaded
        });
    },
  },
  beforeMount () {
    const page = JSON.parse(localStorage.getItem('step'));
    if (page) {
      this.page = page;
      this.steps.map(step => {
        if (step.step <= page) {
          step.completed = true;
        }
      });
    }
  },
  created () {
    this.isLoadingPage = true;
    const me = this.$oauth2.me;
    if (!(me?.product) || (me?.product?.expired)) {
      this.$router.replace('checkout');
    } else if (me?.companies[0]?.hasBeenSetup) {
      this.$router.replace('dashboard');
    } else {
      this.getBanks();
      this.getSetup();
      this.isLoadingPage = false;
    }
  },
};
</script>

<style lang="scss">
.setup {
  // to do change to svg pattern
  background-image: url(~@/assets/images/bg-setup.jpg);
  background-color: $primary-light;
  background-size: cover;
  align-items: center;
  .container-fluid {
    max-width: 1200px;
    position: relative;
  }
  .custom-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }
  .stepper {
    counter-reset: counter;
    .step {
      counter-increment: counter;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
      display: flex;
      justify-content: left;
      align-items: center;
      &.active {
        .name {
          font-size: 16px!important;
        }
        .dot {
          transform: scale(1.2);
          &::before {
            content: counter(counter)!important;
            font-size: 14px;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      &:nth-of-type(1)::after {
        opacity: 0;
      }
      &::after {
        content: '';
        height: 20px;
        width: 2px;
        border-radius: 1px;
        display: block;
        position: relative;
        background: $primary;
        bottom: 30px;
        left: 13px;
        order: -1;
      }
      .dot {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        min-width: 24px;
        height: 24px;
        z-index: 1;
        font-weight: 700;
        transform: all 0.5s ease-in;
        margin-bottom: 3px;
        &::before {
          content: counter(counter);
          position: absolute;
          z-index: -1;
          background: $primary;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .name {
        color: $primary;
        margin-left: 10px;
        font-weight: 600;
      }
      &.disabled {
        opacity: 0.4;
        transition: opacity 0.5s ease-in-out;
        cursor: default;
        .dot{
          font-size: 12px;
          &::before {
            height: 24px;
            width: 24px;
          }
        }
        .name {
          font-size: 13px;
        }
      }
      &.completed {
        .dot {
          font-size: 12px;
          &::before {
            content: '';
            display: block;
            background: $primary;
          }
          &::after {
            content: url('~@/assets/images/check-icon.svg')!important;
            position: relative;
            top: 2px;
            transform: scale(0.7);
          }
        }
        .name {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
