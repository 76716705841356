<template>
<div class="add-bank-account">
    <form
      @submit.prevent="onSubmit"
      class="text-start"
      novalidate
    >
    <h2 class="mb-4">{{ account ? 'Modifica conto' : 'Aggiungi conto' }}</h2>

    <div class="row mb-4 d-none" v-if="!account">
      <div class="col-12">
        <div class="selector" id="selector">
          <button class="btn w-50 option" @click="manualMode = true;" :class="{ active: manualMode }">
            Manuale
          </button>
          <button class="btn w-50 option" @click="manualMode = false;" :class="{ active: !manualMode }">
            Semplificata
          </button>
        </div>
      </div>
    </div>

    <div v-if="manualMode">
      <div class="row">
          <div class="col-6">
              <b-form-group
                  class="mb-2"
                  id="input-group-bank"
                  label="Banca"
                  label-for="input-bank"
                  :class="{ invalid: !isValidBank && showErrorBank }"
              >
                  <vue-select v-model="bank" @search:blur="showErrorBank = true" :options="banks" label="name" placeholder="Seleziona una banca..." :clearable="false" >
                    <template #option="row">
                        <div class="list-element" :title="row.name">
                        <img :src="row.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)" class="bank-logo mr-2">
                        {{ row.name }}
                        </div>
                    </template>
                    <template #selected-option="row">
                      <div :title="row.name">
                        <img
                          :src="row.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)"
                          class="bank-logo mr-2"
                        />
                        {{ row.name }}
                      </div>
                    </template>
                    <template v-slot:no-options>
                      <template>
                        <div class="text-center py-2">
                          Nessuna banca trovata
                        </div>
                      </template>
                    </template>
                  </vue-select>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci una banca
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-alias"
                  label="Alias"
                  label-for="input-alias"
                  :class="{ invalid: !isValidAlias && showErrorAlias }"
              >
                  <b-form-input
                  id="input-alias"
                  v-model="form.alias"
                  type="text"
                  @blur="showErrorAlias = true"
                  placeholder="Inserisci un alias"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAlias || !showErrorAlias }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido (max 20 caratteri)
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row">
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-iban"
                  label="IBAN"
                  label-for="input-iban"
                  :class="{ invalid: !isValidIban && showErrorIban }"
              >
                  <b-form-input
                  id="input-iban"
                  v-model="form.iban"
                  type="text"
                  @blur="showErrorIban = true"
                  placeholder="Inserisci un iban"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidIban || !showErrorIban }" >
                      <i class="fas fa-exclamation-circle"></i> Inserisci un IBAN valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row">
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-fido"
                  label="Fido"
                  label-for="input-fido"
                  :class="{ invalid: !isValidFido && showErrorFido }"
              >
                  <b-form-input
                  id="input-fido"
                  v-model="form.fido"
                  type="number"
                  placeholder=""
                  @blur="showErrorFido = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFido || !showErrorFido }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                class="mb-2"
                id="input-group-anticipi"
                :class="[{ invalid: !isValidContoAnticipi && showErrorContoAnticipi && !form.anticipoRiba }, { disabled: form.anticipoRiba }]"
                :disabled="form.anticipoRiba"
              >
                <label>Conto anticipi</label>
                <b-form-input
                id="input-anticipi"
                v-model="form.contoAnticipi"
                type="number"
                @blur="showErrorContoAnticipi = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidContoAnticipi || !showErrorContoAnticipi || form.anticipoRiba }" >
                <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-fee"
                  label="Commissioni bonifico"
                  label-for="input-fee"
                  :class="{ invalid: !isValidFee && showErrorFee }"
              >
                  <b-form-input
                  id="input-fee"
                  v-model="form.fee"
                  type="number"
                  placeholder=""
                  @blur="showErrorFee = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFee || !showErrorFee }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
      </div>

      <div class="row">
          <div class="col">
              <b-form-group
                  class="mb-2"
                  id="input-group-account-balance"
                  label="Saldo contabile"
                  label-for="input-account-balance"
                  :class="{ invalid: !isValidAccountBalance && showErrorAccountBalance }"
              >
                  <b-form-input
                  id="input-account-balance"
                  v-model="form.accountBalance"
                  type="number"
                  placeholder=""
                  @blur="showErrorAccountBalance = true"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="false" :class="{ invisible: isValidAccountBalance || !showErrorAccountBalance }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
                  </b-form-invalid-feedback>
              </b-form-group>
          </div>
          <div class="col">
              <b-form-group
                  class="mb-2 disabled"
                  id="input-group-available-balance"
                  label="Saldo disponibile"
                  label-for="input-available-balance"
              >
                  <b-form-input
                  id="input-available-balance"
                  v-model="availableBalance"
                  type="text"
                  placeholder=""
                  disabled
                  readonly
                  ></b-form-input>
              </b-form-group>
          </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="colours">Colore</label>
          <div class="form-group mb-3">
            <div class="colours" id="colours">
                <div v-for="el in list" :key="`color_${el}`" class="circle d-block" :class="[`bank-variant-${el}`, `border-bank-variant-${el}`, {active: (form.colour === el)}]" @click="form.colour = el" tabindex="0" @keydown.enter="form.colour = e"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-auto">
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.mainBank"
            name="checkbox-1"
          >
            Banca principale
            <b-form-invalid-feedback :state="false">Puoi selezionare solo una banca principale</b-form-invalid-feedback>
          </b-form-checkbox>
        </div>
        <div class="col-auto">
          <b-form-checkbox
            id="checkbox-2"
            v-model="form.anticipoRiba"
            name="checkbox-2"
            @change="form.contoAnticipi = ''; showErrorContoAnticipi = false;"
          >
            Anticipo RiBa SBF in conto
          </b-form-checkbox>
        </div>
      </div>
      <custom-button
        :isLoading="loading"
        label="Salva"
        class="w-100"
        @click.prevent.native="onSubmit"
      />
    </div>

    <div v-else>
      <div class="row">
        <div class="col-12">
          <p>
            Per collegare automaticamente i tuoi conti a NinfaFin, effettua la registrazione su Fabrick.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
            <b-form-group
                class="mb-3"
                id="input-group-name"
                label="Nome"
                label-for="input-name"
                :class="{ invalid: !isValidName && showErrorName }"
            >
                <b-form-input
                id="input-name"
                v-model="user.name"
                type="text"
                @blur="showErrorName = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidName || !showErrorName }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                </b-form-invalid-feedback>
            </b-form-group>
          </div>
        <div class="col-6">
            <b-form-group
                class="mb-3"
                id="input-group-surname"
                label="Cognome"
                label-for="input-surname"
                :class="{ invalid: !isValidSurname && showErrorSurname }"
            >
                <b-form-input
                id="input-surname"
                v-model="user.surname"
                type="text"
                @blur="showErrorSurname = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSurname || !showErrorSurname }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un cognome valido
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <b-form-group
          class="w-100"
          :class="{ invalid: !isValidDateOfBirth && showErrorDateOfBirth }"
          >
            <label for="date-picker">Inizio</label>
            <b-form-input
              type="date"
              id="date-picker"
              v-model="user.dateOfBirth"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidDateOfBirth || !showErrorDateOfBirth }" >
              <i class="fas fa-exclamation-circle"></i> La data inserita deve precedere la data di fine
            </b-form-invalid-feedback>
          </b-form-group>
          </div>
        <div class="col-4">
            <b-form-group
                class="mb-3"
                id="input-group-place-of-birth"
                label="Nome"
                label-for="input-place-of-birth"
                :class="{ invalid: !isValidPlaceOfBirth && showErrorPlaceOfBirth }"
            >
                <b-form-input
                id="input-place-of-birth"
                v-model="user.placeOfBirth"
                type="text"
                @blur="showErrorPlaceOfBirth = true"
                ></b-form-input>
                <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPlaceOfBirth || !showErrorPlaceOfBirth }" >
                    <i class="fas fa-exclamation-circle"></i> Inserisci un nome valido
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            class="mb-3"
            id="input-group-fiscal-code"
            label="Codice Fiscale"
            label-for="input-fiscal-code"
            :class="{ invalid: !isValidFiscalCode && showErrorFiscalCode}"
          >
            <b-form-input
            id="input-fiscal-code"
            v-model="user.fiscalCode"
            type="text"
            @blur="showErrorFiscalCode = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidFiscalCode || !showErrorFiscalCode }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci una codice fiscale valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <custom-button
        :isLoading="loading"
        label="Continua"
        class="w-100"
        @click.prevent.native="onSubmit"
      />
    </div>

    <ErrorCard v-if="errorMessage">
      <template #message >
        {{ errorMessage }}
      </template>
    </ErrorCard>
  </form>
</div>
</template>

<script>
import ErrorCard from '@/views/components/ErrorCard.vue';
import Button from '@/views/components/Button.vue';
import Select from 'vue-select';
import { BFormGroup, BFormInput, BFormInvalidFeedback, BFormCheckbox } from 'bootstrap-vue';
import { isFilled, isSafe, isCurrency, isItalianIban } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    banks: Array,
    companyId: String,
    account: Object,
  },
  components: {
    ErrorCard,
    'custom-button': Button,
    'vue-select': Select,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      manualMode: true,

      showErrorBank: false,
      showErrorAlias: false,
      showErrorIban: false,
      showErrorFido: false,
      showErrorContoAnticipi: false, // to do update
      showErrorFee: false,
      showErrorAccountBalance: false,

      showErrorName: false,
      showErrorSurname: false,
      showErrorDateOfBirth: false,
      showErrorPlaceOfBirth: false,
      showErrorFiscalCode: false,

      loading: false,
      errorMessage: '',

      list: [14, 8, 5, 1, 4, 6, 2, 7, 3, 9, 11, 12, 13, 10, 15],

      bank: this.account ? this.searchBankById(this.account.bankId) : '',

      // Form
      form: {
        alias: this.account?.alias || '',
        iban: this.account?.iban || '',
        colour: this.account?.colour || null,
        fido: this.account?.fido || null,
        contoAnticipi: this.account?.contoAnticipi || null, // to do update
        fee: this.account?.fee || null,
        accountBalance: this.account?.accountBalance || null,
        mainBank: this.account?.mainBank || false,
        anticipoRiba: this.account ? this.account.anticipoRiba : false,
      },

      user: {
        name: '',
        surname: '',
        dateOfBirth: null,
        placeOfBirth: '',
        fiscalCode: '',
      },
    };
  },
  watch: {
    bank: function () {
      this.form.colour = Number(this.bank.colour) || 1;
      this.form.alias = this.bank.shortName || null;
    },
  },
  computed: {
    availableBalance: {
      get () {
        if (this.form.anticipoRiba) {
          return ((parseFloat(this.form.fido * 100) + parseFloat(this.form.accountBalance * 100)) / 100).toFixed(2);
        } else {
          return ((parseFloat(this.form.fido * 100) + parseFloat(this.form.contoAnticipi * 100) + parseFloat(this.form.accountBalance * 100)) / 100).toFixed(2);
        }
      },
      set (newVal) {
        return newVal;
      },
    },
    // User
    isValidForm () {
      return this.isValidBank && this.isValidAlias && this.isValidIban && this.isValidFido && this.isValidContoAnticipi && this.isValidFee && this.isValidAccountBalance;
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidAlias () {
      return isFilled(this.form.alias) && isSafe(this.form.alias) && this.form.alias.length <= 20;
    },
    isValidIban () {
      return isFilled(this.form.iban) && isItalianIban(this.form.iban);
    },
    isValidFido () {
      return isCurrency(this.form.fido) || !isFilled(this.form.fido);
    },
    isValidContoAnticipi () {
      if (this.form.anticipoRiba) {
        return isCurrency(this.form.contoAnticipi) || !isFilled(this.form.contoAnticipi);
      } else {
        return isFilled(this.form.contoAnticipi) && isCurrency(this.form.contoAnticipi);
      }
    },
    isValidFee () {
      return isCurrency(this.form.fee) || !isFilled(this.form.fee);
    },
    isValidAccountBalance () {
      return isFilled(this.form.accountBalance) && isCurrency(this.form.accountBalance);
    },
    // User
    isValidUser () {
      return this.isValidName && this.isValidSurname && this.isValidDateOfBirth && this.isValidDateOfBirth && this.isValidFiscalCode;
    },
    isValidName () {
      return true;
    },
    isValidSurname () {
      return true;
    },
    isValidDateOfBirth () {
      return true;
    },
    isValidPlaceOfBirth () {
      return true;
    },
    isValidFiscalCode () {
      return true;
    },
  },
  methods: {
    onSubmit: function () {
      this.errorMessage = '';
      if (this.isValidForm && !this.account) {
        this.$emit('addBankAccount', Object.assign(this.form, { bankId: this.bank.id }));
      } if (this.isValidForm && this.account) {
        this.$emit('editBankAccount', Object.assign(this.form, { bankId: this.bank.id }));
      } else {
        this.loading = true;
        if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidAlias) {
          this.showErrorAlias = true;
        } else if (!this.isValidIban) {
          this.showErrorIban = true;
        } else if (!this.isValidFido) {
          this.showErrorFido = true;
        } else if (!this.isValidContoAnticipi) {
          this.showErrorContoAnticipi = true;
        } else if (!this.isValidFee) {
          this.showErrorFee = true;
        } else if (!this.isValidAccountBalance) {
          this.showErrorAccountBalance = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    searchBankById (id) {
      return this.banks.find((bank) => bank.id === id);
    },
  },
};
</script>

<style lang="scss">
.add-bank-account {
  .bank-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .colours {
    display: inline-flex;
    gap: 10px;
  }
  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &:hover {
      filter: brightness(95%);
    }
    &:focus {
      border: none;
    }
    &.active::after {
      content: url(~@/assets/images/check-icon.svg);
      transform: scale(0.8);
      position: absolute;
      height: inherit;
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 7px;
    }
    &.active::before {
      content: '';
      height: inherit;
      width: inherit;
      position: absolute;
      border: 2px solid;
      border-radius: 50%;
      filter: brightness(85%);
    }
  }
}
</style>
