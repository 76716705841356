<template>
  <div class="setup-employees">
      <h5 class="mb-3">
        F24 Dipendenti
      </h5>
      <div class="row">
        <div class="col-6">
          <b-form-group
            class="mb-4"
            id="input-group-f24"
            label="Importo"
            label-for="input-f24"
            :class="{ invalid: !isValidF24 && showErrorF24 }"
          >
            <CurrencyInput v-model="form.f24" @blur="showErrorF24 = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidF24 || !showErrorF24 }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
                class="mb-2"
                id="input-group-bank"
                label="Banca"
                label-for="input-bank"
                :class="{ invalid: !isValidBank && showErrorBank }"
            >
              <vue-select
                v-model="bank"
                @search:blur="showErrorBank = true"
                :options="banks"
                label="name" placeholder="Seleziona una banca..."
                :clearable="false"
              >
                <template #option="row">
                  <div class="list-element" :title="row.name">
                    <img
                      :src="row.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)"
                      class="bank-logo mr-2"
                    />
                    {{ row.name }}
                  </div>
                </template>
                <template #selected-option="row">
                  <div :title="row.name">
                    <img
                      :src="row.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)"
                      class="bank-logo mr-2"
                    />
                    {{ row.name }}
                  </div>
                </template>
                <template v-slot:no-options>
                  <template>
                    <div class="text-center py-2">
                      Nessuna banca trovata
                    </div>
                  </template>
                </template>
              </vue-select>
              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank || !showErrorBank }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una banca
              </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6 d-flex align-items-center">
          <h5>Stipendi dipendenti</h5>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center mb-2">
          <label class="mr-3 mb-0" for="selector">
            Modalità
          </label>
          <div class="selector" id="selector">
            <button class="btn option" @click="defaultView = true;" :class="{ active: defaultView }">
              Dettagliata
            </button>
            <button class="btn option" @click="defaultView = false;" :class="{ active: !defaultView }">
              Cumulata
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group
                class="mb-2"
                id="input-group-bank2"
                label="Banca"
                label-for="input-bank2"
                :class="{ invalid: !isValidBank2 && showErrorBank2 }"
            >
              <vue-select v-model="bank2" @search:blur="showErrorBank2 = true" :options="banks" label="name" placeholder="Seleziona una banca..." :clearable="false" >
                <template #option="row">
                  <div class="list-element" :title="row.name">
                    <img
                      :src="row?.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)"
                      class="bank-logo mr-2"
                    />
                    {{ row.name }}
                  </div>
                </template>
                <template #selected-option="row">
                  <div :title="row.name">
                    <img
                      :src="row?.isCash ? cashLogoDefault : (row.logo ? row.logo : bankLogoDefault)"
                      class="bank-logo mr-2"
                    />
                    {{ row.name }}
                  </div>
                </template>
                <template v-slot:no-options>
                  <template>
                    <div class="text-center py-2">
                      Nessuna banca trovata
                    </div>
                  </template>
                </template>
              </vue-select>
              <b-form-invalid-feedback :state="false" :class="{ invisible: isValidBank2 || !showErrorBank2 }" >
                  <i class="fas fa-exclamation-circle"></i> Inserisci una banca
              </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            class="mb-4"
            id="input-group-payday"
            label="Giorno pagamento stipendi"
            label-for="input-payday"
            :class="{ invalid: !isValidPayday && showErrorPayday }"
          >
            <b-form-input
              id="input-payday"
              v-model="form.payday"
              type="number"
              @blur="showErrorPayday = true"
            ></b-form-input>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidPayday || !showErrorPayday }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un giorno del mese valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div v-if="defaultView">
          <div class="table-wrapper mb-4">
            <b-table id="table" ref="table" :items="form.employees" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" hover show-empty>
              <template #cell(salary)="row">
                {{parseFloat(row.item.salary).toFixed(2)}}
              </template>
              <template #cell(actions)="row">
                <b-dropdown size="sm" :id="`dropdown-${row.item.index}`" right variant="link" toggle-class="text-decoration-none">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item @click="selectedRow = row.item; togglePopup('upsertEmployeePopup');">Modifica</b-dropdown-item>
                  <b-dropdown-item @click="selectedRow = row.item; togglePopup('deleteEmployeePopup');" class="text-red">Elimina</b-dropdown-item>
                </b-dropdown>
              </template>
              <template #empty>
                <div class="d-flex justify-content-center align-items-center py-2">
                  Nessun dipendente aggiunto
                </div>
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col d-flex justify-content-end">
              <button @click="selectedRow = null; togglePopup('upsertEmployeePopup');" class="btn add-employee">
                Aggiungi dipendente
              </button>
            </div>
          </div>
      </div>

      <div v-else class="row">
        <div class="col-6">
          <b-form-group
            class="mb-4"
            id="input-group-salary"
            label="Stipendio netto totale"
            label-for="input-salary"
            :class="{ invalid: !isValidSalary && showErrorSalary }"
          >
            <CurrencyInput id="input-salary" v-model="form.salary" @blur="showErrorSalary = true" />
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidSalary || !showErrorSalary }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci uno stipendio valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            class="mb-3"
            id="input-group-months"
            label="Numero di mensilità"
            label-for="input-months"
            :class="{ invalid: !isValidNumberOfMonths && showErrorNumberOfMonths }"
          >
            <b-form-select
              id="input-months"
              v-model="form.numberOfMonths"
              @blur="showErrorNumberOfMonths = true"
              :options="[12, 13, 14]"
            ></b-form-select>
            <b-form-invalid-feedback :state="false" :class="{ invisible: isValidNumberOfMonths || !showErrorNumberOfMonths }" >
              <i class="fas fa-exclamation-circle"></i> Inserisci un numero di mesi valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-6">
          <custom-button
            label="Indietro"
          class="w-100 opaque"
            @click.prevent.native="$emit('changePage', -1)"
          />
        </div>
        <div class="col-6">
          <custom-button
            :isLoading="loading"
            :class="{disabled: !isValidForm}"
            label="Avanti"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <Popup ref="upsertEmployeePopup" class="md">
        <template #fullContent>
          <UpsertEmployee @save="upsertEmployee" :employee="selectedRow"/>
        </template>
      </Popup>

      <Popup ref="deleteEmployeePopup" class="md">
        <template #title> Sei sicuro di voler eliminare? </template>
        <template #text>
          Il dipendente <b>{{ selectedRow.name }} </b> sarà rimosso dall'elenco.
        </template>
        <template #button>
          <custom-button
            label="Elimina"
            class="w-100 mt-4"
            @click.prevent.native="onDelete"
          />
        </template>
      </Popup>

  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormInvalidFeedback, BTable } from 'bootstrap-vue';
import { isFilled, isCurrency, isNumber } from '@/helpers/formValidation.js';

import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import UpsertEmployee from '@/views/components/Setup/UpsertEmployee.vue';
import Popup from '@/views/components/Popup.vue';
import Select from 'vue-select';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    propForm: {
      type: Object,
      required: true,
    },
    banks: Array,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTable,
    'custom-button': Button,
    CurrencyInput,
    UpsertEmployee,
    Popup,
    'vue-select': Select,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      loading: false,

      showErrorF24: false,
      showErrorBank: false,
      showErrorBank2: false,
      showErrorPayday: false,
      showErrorSalary: false,
      showErrorNumberOfMonths: false,

      defaultView: true,
      selectedRow: null,

      fields: [
        { key: 'name', label: 'Nome', sortable: true },
        { key: 'months', label: 'Mensilità', sortable: true },
        { key: 'salary', label: 'Stipendio netto', sortable: true },
        { key: 'actions', label: ' ' },
      ],
      sortBy: null,
      sortDesc: true,

      form: JSON.parse(JSON.stringify(this.propForm)),
    };
  },
  computed: {
    isValidForm () {
      return this.isValidF24 && this.isValidBank && this.isValidBank2 && this.isValidPayday && (this.defaultView || (this.isValidSalary && this.isValidNumberOfMonths));
    },
    bank: {
      get () {
        return this.form.f24BankId ? this.banks.find(obj => obj.id === this.form.f24BankId) : null;
      },
      set (newValue) {
        this.form.f24BankId = newValue.id;
        return newValue.bankId;
      },
    },
    bank2: {
      get () {
        return this.form.salariesBankId ? this.banks.find(obj => obj.id === this.form.salariesBankId) : null;
      },
      set (newValue) {
        this.form.salariesBankId = newValue.id;
        return newValue.bankId;
      },
    },
    isValidF24 () {
      return isCurrency(this.form.f24) && isFilled(this.form.f24);
    },
    isValidBank () {
      return isFilled(this.bank);
    },
    isValidBank2 () {
      return isFilled(this.bank2);
    },
    isValidPayday () {
      return isFilled(this.form.payday) && isNumber(this.form.payday) && this.form.payday <= 31;
    },
    isValidSalary () {
      return isCurrency(this.form.salary) && isFilled(this.form.salary);
    },
    isValidNumberOfMonths () {
      return isFilled(this.form.numberOfMonths) && isNumber(this.form.numberOfMonths) && this.form.numberOfMonths <= 14;
    },
    lengthOfArray () {
      return this.form.employees ? this.form.employees : [];
    },
  },
  methods: {
    upsertEmployee (obj) {
      if (this.selectedRow) {
        const i = this.form.employees.findIndex(employee => this.selectedRow === employee);
        Object.assign(this.form.employees[i], obj);
      } else {
        if (this.form.employees === undefined) {
          this.form.employees = [];
        }
        this.form.employees.push(obj);
      }

      this.togglePopup('upsertEmployeePopup');
      this.selectedRow = null;
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    onDelete () {
      const i = this.form.employees.findIndex(employee => this.selectedRow === employee);
      this.form.employees.splice(i, 1);

      this.togglePopup('deleteEmployeePopup');
      this.selectedRow = null;
    },
    onSubmit () {
      if (this.isValidForm) {
        if (this.defaultView === true) {
          this.form.salary = null;
          this.form.numberOfMonths = null;
        }
        this.$emit('saveData', this.form);
      } else {
        this.loading = true;
        if (!this.isValidF24) {
          this.showErrorF24 = true;
        } else if (!this.isValidBank) {
          this.showErrorBank = true;
        } else if (!this.isValidBank2) {
          this.showErrorBank2 = true;
        } else if (!this.isValidPayday) {
          this.showErrorPayday = true;
        } else if (this.defaultView) {
          if (!this.isValidSalary) {
            this.showErrorSalary = true;
          } else if (!this.isValidNumberOfMonths) {
            this.showErrorNumberOfMonths = true;
          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
  mounted () {
    if (this.form.salary !== null || this.form.numberOfMonths !== null) {
      this.defaultView = false;
    }
  },
};
</script>

<style lang="scss">
.setup-employees  {

  .selector {
    background: $primary-lighter;
    padding: 3px;
    border-radius: 12px;
    .option {
      border: none;
      border-radius: 10px;
      padding: 5px 15px;
      color: $primary;
      opacity: 0.7;
      font-weight: 600;
      font-size: 14px;
      &.active {
        background: white;
        opacity: 1;
      }
    }
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    height: auto;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
    }
    thead {
      border: none!important;
      background: $fin-white;
      th {
        padding: 23px 15px;
      }
    }
    td:nth-last-child(2) {
      text-align: right;
    }
    td:nth-last-child(1) {
      width: 20px;
    }
  }

  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .text-red > * {
        color: $danger!important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .add-employee {
    background: $primary;
    color: white;
    padding: 8px 15px;
  }
}
</style>
