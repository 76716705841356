<template>
  <div class="setup-bank-accounts" ref="step-2">
    <div>
      <div class="row mb-4 g-2">
        <div
          v-for="(account, i) in form.bankAccounts"
          :key="`account_${i}_${account.alias}_${lengthOfArray}`"
          class="col-4 py-3"
        >
          <div
            class="account-card text-center"
            :class="[`bank-variant-${account.colour}-light`]"
          >
            <img :src="getLogo(account?.bankId)" class="bank-logo md mb-2">
            <h6
              class="text-center mb-3"
              :class="`text-bank-variant-${account.colour}`"
            >
              {{ account.alias }}
            </h6>
            <span class="iban-tag" :class="`bank-variant-${account.colour}`"
              >IBAN</span
            >
            <div class="iban mt-1">{{ account.iban }}</div>
            <div class="row icons d-flex justify-content-between">
              <div class="col-auto">
                <button @click="setMainBank(account)">
                  <i
                    v-if="account.mainBank === true"
                    class="fas fa-star"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                  <i
                    v-else
                    class="far fa-star"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
              <div class="col-auto">
                <button
                  @click="
                    togglePopup('upsertPopup');
                    selectedItem = account;
                  "
                >
                  <i
                    class="fas fa-pen"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
                <button
                  @click="
                    togglePopup('deletePopup');
                    selectedItem = account;
                  "
                >
                  <i
                    class="fas fa-trash"
                    :class="`text-bank-variant-${account.colour}`"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 py-3">
          <div
            type="button"
            class="add-button"
            :class="{invalid: showErrorBankAccounts && lengthOfArray < 1}"
            @click="
              togglePopup('upsertPopup');
              selectedItem = null;
            "
          >
            <i class="fas fa-plus-circle pb-2"></i>
            <span>Aggiungi conto</span>
          </div>
        </div>
      </div>

      <h5 class="mb-3">Cassa</h5>
      <div class="row">
        <div class="col-6">
          <b-form-group
            class="m-0"
            id="input-group-cash"
            label="Saldo della cassa"
            label-for="input-cash"
            :class="{ invalid: !isValidCash && showErrorCash }"
          >
            <CurrencyInput id="input-cash" v-model="form.cash" @blur="showErrorCash = true" />
            <b-form-invalid-feedback
              :state="false"
              :class="{ invisible: isValidCash || !showErrorCash }"
            >
              <i class="fas fa-exclamation-circle"></i> Inserisci un importo
              valido
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-6">
        <custom-button
          label="Indietro"
          class="w-100 opaque"
          @click.prevent.native="$emit('changePage', -1)"
        />
        </div>
        <div class="col-6">
          <custom-button
            :isLoading="loading"
            :class="{disabled: !isValidForm}"
            label="Avanti"
            class="w-100"
            @click.prevent.native="onSubmit"
          />
        </div>
      </div>

      <Popup @closePopup="togglePopup('upsertPopup')" ref="upsertPopup">
        <template #fullContent>
          <UpsertBankAccount
            :banks="banks"
            @addBankAccount="addBankAccount"
            @editBankAccount="editBankAccount"
            :account="selectedItem"
          />
        </template>
      </Popup>

      <Popup
        @closePopup="togglePopup('deletePopup')"
        ref="deletePopup"
        class="md"
      >
        <template #title> Sei sicuro di voler eliminare? </template>
        <template #text>
          Il conto <b>{{ selectedItem.alias }}</b> con iban <b>{{ selectedItem.iban }}</b> verrà rimosso dall'elenco
        </template>
        <template #button>
          <custom-button
            label="Elimina"
            class="w-100 mt-4"
            @click.prevent.native="deleteBankAccount(selectedItem)"
          />
        </template>
      </Popup>

    </div>
  </div>
</template>

<script>
import Button from '@/views/components/Button.vue';
import CurrencyInput from '@/views/components/CurrencyInput.vue';
import Popup from '@/views/components/Popup.vue';
import UpsertBankAccount from '@/views/components/Setup/UpsertBankAccount.vue';

import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import { isFilled, isCurrency } from '@/helpers/formValidation.js';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    propForm: {
      type: Object,
      required: true,
    },
    banks: {
      type: Array,
      required: true,
    },
  },
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    CurrencyInput,
    'custom-button': Button,
    Popup,
    UpsertBankAccount,
  },
  data () {
    return {
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,

      loading: false,
      showErrorBankAccounts: false,
      showErrorCash: false,
      selectedItem: null,

      form: JSON.parse(JSON.stringify(this.propForm)),
    };
  },
  computed: {
    isValidForm () {
      return this.isValidCash && this.lengthOfArray > 0;
    },
    isValidCash () {
      return isFilled(this.form.cash) && isCurrency(this.form.cash);
    },
    lengthOfArray () {
      return this.form.bankAccounts ? this.form.bankAccounts.length : 0;
    },
    cash2: {
      get () {
        return this.propForm.cash;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    togglePopup (ref) {
      return this.$refs[ref].togglePopup();
    },
    addBankAccount (account) {
      if (this.form.bankAccounts === undefined) {
        this.form.bankAccounts = [];
      }

      this.form.bankAccounts.push(account);

      if (account.mainBank) {
        this.setMainBank(account);
      }
      this.togglePopup('upsertPopup');
    },
    editBankAccount (account) {
      const i = this.form.bankAccounts.indexOf(this.selectedItem);
      this.form.bankAccounts.splice(i, 1, account);
      if (account.mainBank) {
        this.setMainBank(account);
      }
      this.togglePopup('upsertPopup');
    },
    deleteBankAccount (account) {
      const i = this.form.bankAccounts.indexOf(account);
      this.form.bankAccounts.splice(i, 1);
      this.togglePopup('deletePopup');
    },
    getLogo (bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return (bank && bank.logo) ? bank.logo : bankLogoDefault;
    },
    setMainBank (account) {
      // to do better
      this.form.bankAccounts.forEach((bankAccount) => {
        bankAccount.mainBank = bankAccount === account;
      });
    },
    onSubmit () {
      if (this.isValidForm) {
        this.$emit('saveData', this.form);
      } else {
        this.loading = true;
        if (this.lengthOfArray <= 0) {
          this.showErrorBankAccounts = true;
        } else if (!this.isValidCash) {
          this.showErrorCash = true;
        }
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
  },
  mounted () {
    this.form = JSON.parse(JSON.stringify(this.propForm));
  },
};
</script>

<style lang="scss">
.setup-bank-accounts {
  .add-button {
    background: $primary-lighter;
    color: $primary;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    padding: auto;
    min-height: 235px;
    min-width: 150px;
    i {
      font-size: 52px;
    }
    &:hover {
      background: rgba($primary-light, 0.6);
    }
    &:active {
      background: rgba($primary-light, 0.8);
    }
    &.invalid {
      color: $danger;
      background: (rgba($danger, 0.1));
    }
  }
  .account-card {
    min-height: 235px;
    height: 100%;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    .iban {
      font-weight: 500;
      font-size: 13px;
    }
    .iban-tag {
      color: $fin-white;
      font-weight: 600;
      font-size: 13px;
      padding: 0px 4px;
      border-radius: 5px;
    }
    button {
      border: none;
      background: none;
    }
    .icons {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
}
</style>
